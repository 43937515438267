@font-face {
  font-family: "phase";
  src: url("Phase-AGX.woff2");
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0px;
  height: 100vh;
  color: white;
  touch-action: none;
  background-color: #27272a;
}
#root {
  height: 100%;
  user-select: none;
}
.App {
  touch-action: inherit;
  overflow: scroll;
  font-family: "phase";
  text-align: center;
  height: 100%;
}
.scroller {
  touch-action: none;
  background-color: #7cb169;
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 20vw;
  /* border-radius: 12px; */
}
.columns {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 24px);
  /* background-color: rgb(16, 185, 129);
  color: white; */
  padding: 12px;
  font-size: 22px;
}
.columns div,
.shuffle div {
  border: 1px solid white;
  border-radius: 12px;
  padding: 12px;
}
.columns div:active,
.shuffle div:active {
  border: 1px solid rgb(173, 217, 182);
  color: rgb(173, 217, 182);
}
.shuffle {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 24px);
  /* background-color: rgb(219, 234, 254); */
  font-size: 22px;
  padding: 12px;
}
.heighttransition {
  overflow: hidden;
}
.connectorcontainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connector {
  font-weight: inherit;
  animation: loading 4s infinite ease-in-out;
  margin: 46px 0px;
  display: inline-block;
  font-size: 6vw;
  /* color: rgb(71, 85, 105); */
  border: 1px solid white;
  border-radius: 6vw;
  padding: 24px;
}
a {
  color: lightblue;
}
.contact {
  font-size: 22px;
  text-align: left;
  margin: 12px;
}
.bigbutton {
  transition: background-color 0.2s;
  font-size: 22px;
  border-top: 1px solid white;
}
.borderbottom {
  border-bottom: 1px solid white;
}
@keyframes loading {
  0% {
    font-variation-settings: "phon" 100, "phtw" 20, "phth" 0;
  }
  20% {
    font-variation-settings: "phon" 0, "phtw" 0, "phth" 100;
  }
  40% {
    font-variation-settings: "phon" 100, "phtw" 0, "phth" 100;
  }
  60% {
    font-variation-settings: "phon" 0, "phtw" 100, "phth" 0;
  }
  80% {
    font-variation-settings: "phon" 100, "phtw" 0, "phth" 0;
  }
  100% {
    font-variation-settings: "phon" 100, "phtw" 20, "phth" 0;
  }
}
.scrollerindi {
  animation: scroll 4s infinite;
  transform: rotate(90deg) translateX(-20px) translateY(-16vw);
  width: 100vh;
  text-align: center;
  transform-origin: top left;
}

@keyframes scroll {
  0% {
    transform: rotate(90deg) translateX(-20px) translateY(-16vw);
  }
  10% {
    transform: rotate(90deg) translateX(20px) translateY(-16vw);
  }
  90% {
    transform: rotate(90deg) translateX(20px) translateY(-16vw);
  }
  100% {
    transform: rotate(90deg) translateX(-20px) translateY(-16vw);
  }
}
